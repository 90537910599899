<template>
  <van-config-provider :theme-vars="themeVars">
    <van-field v-model.trim="state.phone" type="tel" maxlength="11" placeholder="请输入手机号码"
      class="border-cell align-items-center" :border="false" @change="onPhoneChange">
      <template #left-icon>
        <van-icon :name="icons.mobile" size="24px" />
      </template>
    </van-field>
    <van-field v-model.number="state.smscode" type="number" maxlength="8" placeholder="请输入4-8位验证码"
      class="border-cell align-items-center" :border="false" @change="onSmscodeChange">
      <template #left-icon>
        <van-icon :name="icons.shield" size="24px" />
      </template>
      <template #button>
        <van-button v-if="state.remainingTime > 0" style="border-radius: 15px" size="small" disabled plain
          type="primary">{{
            state.smsButtonText }}</van-button>
        <van-button v-else size="small" :disabled="state.phone.length != 11" type="primary" style="border-radius: 15px"
          @click="sendCode">{{
            state.smsButtonText
          }}</van-button>
      </template>
    </van-field>
  </van-config-provider>
</template>

<script>
import { Toast } from "vant";
import { watch, onUnmounted, reactive } from "vue";
import { sendSmsCode } from "@/api/sms.service";

export default {
  props: {
    phone: {
      type: String,
      require: false,
    },
    smscode: {
      type: String,
      require: false,
    }
  },
  setup(props, { emit }) {
    let timer = null;
    const icons = {
      mobile: require("@/assets/images/icons/mobile.svg"),
      shield: require("@/assets/images/icons/shield.svg"),
    };
    const themeVars = {
      tabsBottomBarColor: "#222",
      checkboxCheckedIconColor: "#222",
      buttonPrimaryBackgroundColor: "#222",
      buttonPrimaryBorderColor: "#222",
    };
    const state = reactive({
      phone: "",
      smscode: "",
      smsButtonText: "发送验证码",
      remainingTime: 0,
    });

    const checkPhone = () => {
      if (!state.phone) {
        Toast({ message: "请输入手机号码", position: "bottom" });
        return false;
      }
      if (state.phone.length != 11) {
        Toast({ message: "手机号码不正确", position: "bottom" });
        return false;
      }
      const reg = /^1(\d){10}$/;
      if (!reg.test(state.phone)) {
        Toast({ message: "手机号码不正确", position: "bottom" });
        return false;
      }
      return true;
    };

    const tickTick = () => {
      // 定时器 90 秒后可重发短信验证码
      state.remainingTime = 90;
      timer = setInterval(() => {
        if (state.remainingTime == 0) {
          state.smsButtonText = "发送验证码";
          clearInterval(timer);
        } else {
          state.remainingTime--;
          state.smsButtonText = `${state.remainingTime}秒后可重发`;
        }
      }, 1000);
    };

    const sendCode = async () => {
      if (!checkPhone()) {
        return;
      }
      try {
        const resp = await sendSmsCode(
          state.realPhone ? state.realPhone : state.phone
        );
        if (resp.result.BizId) {
          tickTick();
          Toast({ message: "已发送", position: "bottom" });
          state.smscode = "";
        } else {
          Toast.fail(resp.msg);
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast.fail(msg ? msg : "发送验证码失败");
      }
    };

    const onPhoneChange = (value) => {
      emit("change_phone", value);
    };

    const onSmscodeChange = (value) => {
      emit("change_smscode", value);
    };

    watch(() => props.phone, (value) => {
      state.phone = value;
    });

    watch(() => props.smscode, (value) => {
      state.smscode = value;
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return {
      icons,
      themeVars,
      state,
      sendCode,
      onPhoneChange,
      onSmscodeChange,
    };
  },
};
</script>

<style lang="less" scoped>
.border-cell {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  margin-bottom: 20px;
}
</style>
