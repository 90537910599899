<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="ticket-warp" :style="`background-image: url(${ticket.screenshot})`">
      <div class="content my-3">

        <div class="d-flex">
          <van-image v-if="ticket.scenic_spot.preview_list.length > 0" width="100px" height="100px" radius="15px"
            :src="ticket.scenic_spot.preview_list[0]"></van-image>
          <van-image v-else width="100px" height="100px" radius="15px" :src="icons.logo"></van-image>
          <div style="margin-left: 10px;">
            <h4>{{ ticket.name }}</h4>
            <div class="mb-2 text-muted"><van-icon :name="icons.logo" /> {{ $t('appname') }}</div>
            <div class="text-muted"><van-icon :name="icons.calendar" /> {{ $t('auto_refund_tips') }}</div>
          </div>
        </div>

        <div class="van-hairline--top mt-3"></div>
        <div class="scetion pt-3">
          <div class="label"><van-icon :name="icons.timer" /> {{ $t('play_time_limit') }}</div>
          <div class="value">{{ $t('hour', 24) }}</div>
        </div>

        <div class="van-hairline--top mt-3"></div>
        <div class="scetion pt-3">
          <div class="label"><van-icon :name="icons.checkticket" /> {{ $t('ticket_checking_station') }}</div>
          <div class="value">
            <TicketCheckingStation />
          </div>
        </div>

        <div class="van-hairline--top mt-3"></div>
        <div class="scetion pt-3">
          <div class="label">{{ $t('payment_method') }}</div>
          <div v-if="wechat" class="mt-3 d-flex align-items-center" @click="showPaymentTips">
            <van-image width="24px" height="24px" radius="10px" style="margin-right: 10px;"
              :src="icons.wechatpay"></van-image>
            <span>{{ $t('wechatpay') }}</span>
            <van-icon class="ms-auto" size="18px" name="arrow" color="#dedede" />
          </div>
          <template v-else>
            <div class="mt-3 d-flex align-items-center" @click="state.payment_method = 'alipay'">
              <van-image width="24px" height="24px" radius="10px" style="margin-right: 10px;"
                :src="icons.alipay"></van-image>
              <span>{{ $t('alipay') }}</span>
              <van-icon v-if="state.payment_method == 'alipay'" class="ms-auto" size="18px" name="success" color="#222" />
            </div>
            <div class="mt-3 d-flex align-items-center" @click="state.payment_method = 'paypal'">
              <van-image width="24px" height="24px" radius="10px" style="margin-right: 10px;"
                :src="icons.paypal"></van-image>
              <span>{{ $t('paypal') }}</span>
              <van-icon v-if="state.payment_method == 'paypal'" class="ms-auto" size="18px" name="success" color="#222" />
            </div>
          </template>
        </div>

        <div class="van-hairline--top mt-3"></div>
        <div class="scetion pt-3">
          <div class="label mb-2">{{ $t('phone_number') }}</div>
          <SmsField v-if="!currentUser?.phone" :phone="state.phone" :smscode="state.smscode" @change_phone="updatePhone"
            @change_smscode="updateSmscode" />
          <van-field v-else type="tel" maxlength="11" class="border-cell align-items-center" disabled :border="false"
            v-model="currentUser.phone">
            <template #left-icon>
              <van-icon :name="icons.mobile" size="24px" />
            </template>
            <template #button>
              <span class="text-muted">{{ $t('has_logged') }}</span>
            </template>
          </van-field>
        </div>

        <div v-show="!wechat && state.payment_method == 'paypal'" class="scetion py-3">
          <div class="priceinfo text-center mb-3 w-100 m-0">
            <div class="text-muted">{{ $t('price') }} <span class="price"><small>$</small>{{ ticket.price_usd }}</span></div>
            <div class="text-muted small">{{ $t('auto_refund_tips') }}</div>
          </div>
          <div id="paypal-button-container"></div>
        </div>
        <van-action-bar v-show="state.payment_method != 'paypal'">
          <div class="priceinfo">
            <div class="text-muted">{{ $t('price') }} <span class="price"><small>¥</small>{{ ticket.price }}</span></div>
            <div class="text-muted small">{{ $t('auto_refund_tips') }}</div>
          </div>
          <van-button type="primary" class="mx-2" block :text="$t('confirm_pay')" @click="handlePurchase" />
        </van-action-bar>

      </div>
    </div>
  </van-config-provider>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast, ActionBar, ActionBarIcon, ActionBarButton, Rate, Popup, Field } from 'vant';
import { getTicketDetail, purchase, getPaypalOrder } from "@/api/ticket.service";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { signaturePack } from "@/api/wechat.service";
import { createDeviceDetector } from "next-vue-device-detector";
import { stripTags } from "@/utils/util";
import TicketCheckingStation from "@/components/TicketCheckingStation";
import SmsField from "@/components/SmsField";
import { useStore } from "vuex";
import { SET_AUTH } from "@/store/modules/auth";
import { loadScript } from '@paypal/paypal-js';
import { useI18n } from "vue-i18n";
export default {
  props: {
    id: {
      type: String,
      require: true,
    }
  },
  components: {
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    [Rate.name]: Rate,
    [Popup.name]: Popup,
    [Field.name]: Field,
    TicketCheckingStation,
    SmsField,
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const device = createDeviceDetector();
    const { mobile, wechat } = device;

    const route = useRoute();
    const router = useRouter();
    const show = ref(false);
    const autosubmit = ref(false);  // 微信环境下，微信授权后自动提交购买
    const logo = require(process.env.VUE_APP_LOGO_DARK);
    const ticket = reactive({ id: 0, price: 0, scenic_spot: { score: 0, description: "", preview_list: [] } });
    const preauth = JSON.parse(window.sessionStorage.getItem("preauth"));
    const state = reactive({ payment_method: "alipay", phone: "", smscode: "" });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const icons = {
      calendar: require("@/assets/images/icons/calendar.svg"),
      timer: require("@/assets/images/icons/timer.svg"),
      checkticket: require("@/assets/images/icons/checkticket.svg"),
      wechatpay: require("@/assets/images/icons/wechatpay.svg"),
      alipay: require("@/assets/images/icons/alipay.svg"),
      logo: require("@/assets/images/icons/logo.svg"),
      mobile: require("@/assets/images/icons/mobile.svg"),
      shield: require("@/assets/images/icons/shield.svg"),
      paypal: require("@/assets/images/icons/paypal.png"),
    };

    const themeVars = {
      // tabsBottomBarColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };

    const loadDetail = async (id) => {
      try {
        const { result } = await getTicketDetail(id);
        Object.assign(ticket, result);
        if (ticket.scenic_spot.preview_list) {
          ticket.scenic_spot.preview_list = JSON.parse(ticket.scenic_spot.preview_list);
        }
        window.localStorage.setItem("seller_id", result.user_id);
        if (autosubmit.value) {
          handlePurchase();
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : t('front_end_error');
        Toast.fail(message);
      }
    };

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow", "chooseWXPay"],
        });
        wx.ready(() => {
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          Toast.fail(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast.fail(msg ? msg : t('front_end_error2'));
        if (!msg) console.log(error);
      }
    };

    onBeforeMount(() => {
      loadScript({ 'client-id': 'sb' }).then((paypal) => {
        paypal
          .Buttons({
            createOrder: handlePurchase,
            onApprove: handleApprove,
          })
          .render('#paypal-button-container')
      });
    });

    onMounted(() => {
      loadDetail(props.id);
      if (wechat) {
        signaturePage();
        state.payment_method = "wxpay";
      }
      if (currentUser.value?.phone) {
        state.phone = currentUser.value.phone;
      } else {
        state.phone = window.localStorage.getItem("buyer_phone");
        state.smscode = window.localStorage.getItem("smscode");
      }
      autosubmit.value = route.query?.autosubmit;
    });

    const handlePurchase = async () => {
      if (!checkPhone()) {
        return;
      }
      if (!currentUser.value && !checkCode()) {
        return;
      }

      // 微信环境先获取 openid，用于支付，授权成功后会跳回本页面继续自动支付
      if (wechat && !preauth) {
        const url = window.location.href.indexOf("?") > 0 ? `${window.location.href}&autosubmit=1` : `${window.location.href}?autosubmit=1`;
        window.localStorage.setItem("buyer_phone", state.phone);
        window.localStorage.setItem("smscode", state.smscode);
        window.sessionStorage.setItem("login_redirect_url", url);
        window.location.href = "/wechat-login";
        return;
      }

      try {
        const data = {
          scenic_spot_ticket_id: ticket.id,
          phone: state.phone, // 会根据 phone 创建用户
          smscode: state.smscode,
          source: "H5移动端",
          openid: preauth?.openid,
          payment_method: state.payment_method,
          is_mobile: mobile ? 1 : 0,
          is_wechat: wechat ? 1 : 0,
        };
        const { result } = await purchase(data);
        window.localStorage.removeItem("smscode");
        window.sessionStorage.removeItem("preauth");
        window.sessionStorage.removeItem("login_redirect_url");

        // 自动登录
        if (result.authdata) {
          store.commit(SET_AUTH, result.authdata);
        }

        if (!wechat) {
          Toast.loading({
            forbidClick: true,
          });
        }

        // PayPal
        if (data.payment_method == "paypal") {
          // 用户支付完成后，会回调 handleApprove() 函数
          return result.paypal_order.data.id;
        }

        // 支付宝
        if (data.payment_method == "alipay") {
          const div = document.createElement("alipayForm");
          div.innerHTML = result.form;
          document.body.appendChild(div);
          setTimeout(() => {
            document.getElementById("alipay_submit").submit(); // <form id="alipay_submit">
          }, 1500);
          return;
        }

        // H5支付直接跳转
        if (result.mweb_url) {
          window.location.href = result.mweb_url;
          return;
        }

        // 微信公众号JSSDK支付
        wx.chooseWXPay({
          timestamp: result.timeStamp,
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result.paySign,
          success: () => {
            Toast.success({
              message: t('payment_successful'),
              forbidClick: true,
              overlay: true,
            });
            router.push({ name: "TicketPayResult", params: { trade_no: result.trade_no } });
          },
          error: (res) => {
            Toast.fail(res.errMsg ? res.errMsg : t('payment_failed'));
          },
          cancel: () => {
            Toast.fail(t('payment_cancelled'));
          },
        });
        router.push({ name: "TicketPayResult", params: { trade_no: result.trade_no } });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : t('front_end_error');
        Toast.fail(message);
      }
    };

    const handleApprove = async (data, actions) => {
      console.log("handleApprove", data, actions);
      // 同步检查 paypal 订单状态
      try {
        const { result } = await getPaypalOrder(data.orderID);
        if (result.data.status == "APPROVED") {
          router.push({ name: "TicketPayResult", params: { trade_no: data.orderID } });
        } else {
          Toast.fail(result.data.status);
        }
        // console.log(result);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : t('front_end_error');
        Toast.fail(message);
      }
    }

    const checkPhone = () => {
      const phone = state.phone;
      if (!phone) {
        Toast({ message: t("phone_number_error"), position: "bottom" });
        return false;
      }
      if (phone.length != 11) {
        Toast({ message: t("phone_number_error"), position: "bottom" });
        return false;
      }
      const reg = /^1(\d){10}$/;
      if (!reg.test(phone)) {
        Toast({ message: t("phone_number_error"), position: "bottom" });
        return false;
      }
      return true;
    };

    const checkCode = () => {
      if (!state.smscode) {
        Toast({ message: t("placeholder_smscode_input"), position: "bottom" });
        return false;
      }
      if (state.smscode.toString().length < 4) {
        Toast({ message: t("smscode_error"), position: "bottom" });
        return false;
      }
      return true;
    };

    const updatePhone = async (phone) => {
      state.phone = phone.target._value;
    };

    const updateSmscode = async (smscode) => {
      state.smscode = smscode.target._value;
    };

    const showPaymentTips = () => {
      Toast({ message: t('payment_tool_tips', {payment_tool: t('wechatpay')}), position: "bottom" });
    }

    return { show, logo, icons, ticket, state, wechat, themeVars, currentUser, stripTags, handlePurchase, updatePhone, updateSmscode, showPaymentTips }
  }
}
</script>

<style lang="less" scoped>
.ticket-warp {
  width: 100vw;
  padding: 0;
  vertical-align: middle;
  background-color: #fff;
  padding-top: 80px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  .content {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 20px;
    padding-bottom: 80px;

    .van-button {
      border-radius: 20px;
    }

    .scetion {

      .label {
        color: #969799;
      }

      .value {
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    .border-cell {
      border: 1px solid #e5e5e5;
      border-radius: 20px;
      margin-bottom: 20px;
    }

    .van-action-bar {
      padding: 20px 0;
      background-color: #fff;
    }

    .priceinfo {
      width: 80%;
      margin-left: 10px;

      .price {
        font-size: 32px;
        color: #fa607d;

        small {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
